import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, OnInit } from '@angular/core';
import { EventsService, MockAddCashbackBonusForm, MockChangeCashbackBonusForm, WlCashbackBonus } from '../../../../services/autogen/Events';
import { formatBoolean, GaMessagingService, GaPagedResult, GaPagingForm, GaTableData, isNullOrUndefined } from '@koddington/ga-common';
import { filter, map, take } from 'rxjs/operators';
import { SearchNavigationService } from '../../../shared/services/search-navigation.service';
import { ActivatedRoute, Params } from '@angular/router';
import { UsersListByIdForm } from '../../../../services/autogen/Shared';
import { builderStrictToModel } from '../../../shared/common/operation/builder-operation';
import { AddCashbackBonusViewModel } from './models/add-cashback-bonus-view-model';
import { CashbackBonusListViewModel } from './models/cashback-bonus-list-view-model';
import { AddCashbackBonusValidator } from './validators/add-cashback-bonus-validator';

@UntilDestroy()
@Component({
    selector: 'app-add-cashback-bonus',
    templateUrl: './add-cashback-bonus.component.html',
    styleUrls: ['./add-cashback-bonus.component.scss']
})
export class AddCashbackBonusComponent implements OnInit {
    public readonly addModel: AddCashbackBonusViewModel = new AddCashbackBonusViewModel();
    public readonly searchModel: CashbackBonusListViewModel = new CashbackBonusListViewModel();
    public pagedResult: GaPagedResult<WlCashbackBonus>;
    public tableData: GaTableData<WlCashbackBonus>;
    public loading = true;

    constructor(private readonly service: EventsService,
                private readonly validator: AddCashbackBonusValidator,
                private readonly _navigation: SearchNavigationService,
                private readonly _activeRoute: ActivatedRoute,
                private readonly messaging: GaMessagingService) {
    }

    public ngOnInit(): void {
        this.updateCurrentTableState();
    }

    public pageChanged(form: GaPagingForm): void {
        this.searchModel.offset.strictValue = form.offset;
        this.searchModel.count.strictValue = form.count;

        this.handleFilterChange();
    }

    public search(): void {
        this.searchModel.offset.strictValue = 0;
        this.handleFilterChange();
    }

    protected add(): void {
        this.loading = true;
        const errors = this.validator.validate(this.addModel);
        if (errors.length > 0) {
            return;
        }
        const form = new MockAddCashbackBonusForm();
        form.userId = this.addModel.userId.strictValue;

        this.service.addCashbackBonus(form).pipe(take(1), untilDestroyed(this))
            .subscribe(value => {
                this.messaging.showMessage('Добавлен пользователь не забравший бонус');
                this.updateCurrentTableState();
            });
    }

    private updateCurrentTableState() {
        this._activeRoute.queryParams.pipe(
            map(value => this.initFindViewModel(value)),
            map(() => this.createForm()),
            take(1)
        ).subscribe((value) => this.load(value));
    }

    private initFindViewModel(params: Params) {
        this.searchModel.userId.strictValue = !isNullOrUndefined(params['userId']) ? Number(params['userId']) : null;
        this.searchModel.offset.strictValue = !isNullOrUndefined(params['offset']) ? Number(params['offset']) : 0;
        this.searchModel.count.strictValue = !isNullOrUndefined(params['count']) ? Number(params['count']) : 30;
    }

    private createForm(): UsersListByIdForm {
        return builderStrictToModel(UsersListByIdForm, this.searchModel);
    }

    private load(form: UsersListByIdForm): void {
        this.service.getCashbackBonusList(form)
            .pipe(untilDestroyed(this))
            .subscribe(res => {
                this.pagedResult = res;
                this.mapTableData(res.results);

                this.loading = false;
            });
    }

    private handleFilterChange(): void {
        const params: Params = {
            tab: 'CashbackBonus',
            userId: this.searchModel.userId.hasStrictValue ? Number(this.searchModel.userId.strictValue) : null,
            offset: this.searchModel.offset.hasStrictValue ? this.searchModel.offset.strictValue : 0,
            count: this.searchModel.count.hasStrictValue ? this.searchModel.count.strictValue : 30
        };

        this._navigation.search(this._activeRoute, params);
    }

    private delete(cashbackBonusId: number): void {
        this.service.deleteCashbackBonus(new MockChangeCashbackBonusForm({cashbackBonusId: cashbackBonusId}))
            .pipe(take(1), filter(value => !this.messaging.tryShowError(value)))
            .subscribe(value => {
                value.result === true ? this.messaging.showMessage('Запись успешно удалена') :
                    this.messaging.showMessage('Удаление записи не произошло');
                window.location.reload();
            });
    }

    private markCashbackUsed(cashbackBonusId: number): void {
        this.service.markCashbackBonusUsed(new MockChangeCashbackBonusForm({cashbackBonusId: cashbackBonusId}))
            .pipe(take(1), filter(value => !this.messaging.tryShowError(value)))
            .subscribe(value => {
                value.result === true ? this.messaging.showMessage('Бонус кэшкэка изменен на забранный') :
                    this.messaging.showMessage('Ошибка. Изменение не выполнено.');
                window.location.reload();
            });
    }

    private mapTableData(results: WlCashbackBonus[]) {
        this.tableData = new GaTableData<WlCashbackBonus>()
            .addSimpleColumn(u => u.cashbackBonusId, {title: 'Id бонуса кэшбэка', widthSize: 150})
            .addSimpleColumn(u => u.userId, {title: 'Id пользователя', widthSize: 150})
            .addSimpleColumn(u => formatBoolean(u.isUsed), {title: 'Бонус забран?', widthSize: 150})
            .addActionColumn(_ => 'Удалить',
                (elem) => this.delete(elem.cashbackBonusId), {title: '', widthSize: 150})
            .addActionColumn(_ => 'Забрать бонус',
                (elem) => this.markCashbackUsed(elem.cashbackBonusId),
                {title: '', widthSize: 160, hideContent: elem => elem.isUsed})
            .setData(results);
    }
}
