import { Component, OnDestroy, OnInit } from '@angular/core';
import { MockBroadcastingTemplate, TemplateSearchForm } from 'src/app/services/autogen/PushGate';
import { TemplatesService } from 'src/app/services/autogen/PushGate';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GaPagedResult, GaPagingForm, GaPanelGridConfig, GaTableData } from '@koddington/ga-common';
import { GaSearchManagementStateOptions, GaSearchManagementStateService } from '../../shared/services/ga-search-management-state.service';
import { PushTemplatesSearchModel } from './models/push-templates-search-model';

@UntilDestroy()
@Component({
  selector: 'app-push-templates-list',
  templateUrl: './push-templates-list.component.html',
  providers: [GaSearchManagementStateService],
})
export class PushTemplatesListComponent implements OnInit, OnDestroy {
  public tableData: GaTableData<MockBroadcastingTemplate>;
  public pagingResult: GaPagedResult<MockBroadcastingTemplate>;
  public searchPanelGrid = new GaPanelGridConfig();
  private searchModel = new PushTemplatesSearchModel();

  constructor(private readonly _searchManagementState: GaSearchManagementStateService<GaPagedResult<MockBroadcastingTemplate>>,
              private readonly _service: TemplatesService) {
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy() {}

  protected pageChanged(pagingEvent: GaPagingForm): void {
    this._searchManagementState.nextSearch(pagingEvent);
  }

  protected pageFiltered(page: GaPagingForm = null): void {
    this._searchManagementState.nextSearch(page, true);
  }

  private init(): void {
    this.searchPanelGrid.addNumbers(this.searchModel.id, 'Id');
    this._searchManagementState.initFromOptions(
        (form) => this._service.list(form as TemplateSearchForm),
        this.searchModel,
        new GaSearchManagementStateOptions(u => u['tab'] = 'Push')
    );
    this._searchManagementState.searchState.pipe(untilDestroyed(this)).subscribe((result: GaPagedResult<MockBroadcastingTemplate>) => {
      this.pagingResult = result;
      this.mapToTable(result.results);
      this._searchManagementState.loadingStatus = false;
    });
  }

  private mapToTable(models: MockBroadcastingTemplate[]): void {
    this.tableData = new GaTableData<MockBroadcastingTemplate>()
        .addSimpleColumn((elem) => elem.templateCode, { title: 'Код шаблона', widthSize: 150 })
        .addSimpleColumn((elem) => elem.valuesView, { title: 'Наименование', widthSize: 450 })
        .setData(models);
  }

  get loading(): boolean {
    return this._searchManagementState.loadingStatus;
  }
}
