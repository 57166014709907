import { ActivatedRoute, Params } from '@angular/router';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { filter, finalize, map, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { GaPagingForm, isNullOrUndefined, StrictFormControl, stringIsNullOrEmpty } from '@koddington/ga-common';
import { SearchNavigationService } from './search-navigation.service';
import { GaKeysExtensions } from '@koddington/ga-common';
import dayjs from 'dayjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { builderStrictToModel, getKeys, KeyMap } from '../common/operation/builder-operation';

export interface IPagingViewModel {
    offset: StrictFormControl<number>;
    count: StrictFormControl<number>;
}

// tslint:disable-next-line
export type MapStrictValue = {
    controlName: string; // This is name of control in view model Example: ViewModel contains - example: StrictFormControl - controlName is 'example'
    strictKey: string; // This is key from object strict form control Example: strictValue = {name: 'foo', id: 12, term: 'test'} - keyValue = 'name' or 'id' or 'term'
    formKey: string; // This is key of Params equal to the name of the form sending in backend controller
    initControlGetter?: (elem: any) => Observable<any>; // will be called in initViewModel and will build control after page init
};

// tslint:disable-next-line
export type LoadingFlag = {
    loading: boolean;
};

export class WlPagingForm {
}

export class GaSearchManagementStateOptions {
    fnPageParamsSetter?: (u: Params) => void;
    mapper?: MapStrictValue[] = [];

    constructor(fnPageParamsSetter?: (u: Params) => void, ...mapper: MapStrictValue[]) {
        this.fnPageParamsSetter = fnPageParamsSetter;
        this.mapper = mapper;
    }
}

@UntilDestroy()
@Injectable()
export class GaSearchManagementStateService<T> implements OnInit, OnDestroy {
    private searchViewModel: IPagingViewModel;
    private searchState$: Observable<T>;
    private mapper: Array<MapStrictValue>;
    private innerLoadFlag: LoadingFlag = {
        loading: true,
    };

    private readyToLoad = new ReadyToLoadValidator();
    private options: GaSearchManagementStateOptions;

    constructor(private readonly _navigation: SearchNavigationService,
                private readonly _route: ActivatedRoute) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.mapper.length = 0;
        this.innerLoadFlag = undefined;
        this.readyToLoad?.dispose();
    }

    public init(
        fnList: (value: WlPagingForm) => Observable<T>,
        viewModel: IPagingViewModel,
        ...mapper: MapStrictValue[]): void {
        return this.initFromOptions(fnList, viewModel, {mapper: mapper});
    }

    public initFromOptions(
        fnList: (value: WlPagingForm) => Observable<T>,
        viewModel: IPagingViewModel,
        options: GaSearchManagementStateOptions): void {
        if (isNullOrUndefined(viewModel)) {
            throw new Error('Search view model is a required parameter');
        }

        this.options = options;

        if (map) {
            this.mapper = this.options.mapper;
        }

        this.readyToLoad.controlsMapped
            .pipe(
                untilDestroyed(this)
            )
            .subscribe(_ => {
                const viewModelParams = this.buildParamsFromViewModel();
                if (this.compareParams(viewModelParams, this._route.snapshot.queryParams)) {
                    this.readyToLoad.setQueryParamsIsValid(true);
                    return;
                }

                this._navigation
                    .search(this._route, this.withFnParams(viewModelParams), true)
                    .then(__ => this.readyToLoad.setQueryParamsIsValid(true));
            });

        this.searchViewModel = viewModel;
        this.buildViewModelAndMappersSubject(options.mapper);

        if (!!this.searchState$) {
            return;
        }

        this.searchState$ = this.readyToLoad.isValid.pipe(
            filter(u => u === true),
            map(() => {
                if (this.innerLoadFlag) {
                    this.innerLoadFlag.loading = true;
                }

                return this.createPagingForm();
            }),
            switchMap((value: WlPagingForm) => fnList(value)),
            untilDestroyed(this)
        );

        this._route.queryParams.pipe(
            untilDestroyed(this)
        ).subscribe(_ => {
            this.buildViewModelAndMappersSubject(options.mapper);
        });
    }

    public nextSearch(page: GaPagingForm = null, resetOffsetRequired: boolean = false): void {
        const params = this.buildParamsFromViewModel(page?.offset, resetOffsetRequired);
        this.readyToLoad.setQueryParamsIsValid(false);
        this._navigation.search(this._route, this.withFnParams(params));
    }

    private buildViewModelAndMappersSubject(mapper: MapStrictValue[]): void {
        this.readyToLoad.setMappersWithGetterCount(mapper?.filter(u => !!u.initControlGetter).length ?? 0);
        this.updateViewModel(this._route.snapshot.queryParams);
    }

    private updateViewModel(params: Params): void {
        const keys = getKeys(this.searchViewModel);

        const keysWithoutMappers = keys.filter(key => !this.mapper.some(u => u.controlName === key));
        const keysWithMappersAndGetters = keys.filter(key => this.mapper.some(u => u.controlName === key && !!u.initControlGetter));

        keysWithoutMappers.forEach((key) => {
            const currentParam = params[key];
            if (isNullOrUndefined(currentParam)) {
                return;
            }

            const dayjsVal = GaKeysExtensions.getValidDayjsByKey(params, key as string);
            const dayjsConstructorName = 'Dayjs';
            if (this.searchViewModel[key].strictType === dayjsConstructorName || dayjsVal !== null) {
                this.searchViewModel[key].strictValue = dayjsVal;
                return;
            }
            if (this.searchViewModel[key].strictType === Boolean.name || typeof currentParam === Boolean.name.toLowerCase()) {
                this.searchViewModel[key].strictValue = GaKeysExtensions.getBooleanByKey(params, key as string);
                return;
            }
            const number = GaKeysExtensions.getNumberByKey(params, key as string);
            if (this.searchViewModel[key].strictType === Number.name && !isNullOrUndefined(number)) {
                this.searchViewModel[key].strictValue = number;
                return;
            }
            if (this.searchViewModel[key].strictType === String.name) {
                this.searchViewModel[key].strictValue = GaKeysExtensions.getNotEmptyStringByKey(params, key as string);
                return;
            }
        });

        if (keysWithMappersAndGetters.length === 0) {
            this.readyToLoad.markInitControlsMapped();
            return;
        }

        keysWithMappersAndGetters.forEach((key) => {
            const mapper = this.mapper.find(u => u.controlName === key);
            const param = params[mapper.formKey];
            if (!param) {
                this.searchViewModel[key].strictValue = null;
                this.readyToLoad.decreaseInitBuildersToLoad();
                return;
            }

            mapper.initControlGetter(param)
                  .pipe(
                      take(1),
                      finalize(() => this.readyToLoad.decreaseInitBuildersToLoad())
                  )
                  .subscribe(res => {
                      this.searchViewModel[key].strictValue = res;
                  });
            return;
        });
    }

    private buildParamsFromViewModel(pageOffset?: number, resetOffsetRequired?: boolean): Params {
        const keys = getKeys(this.searchViewModel);
        const params: Params = {};
        keys.forEach((u) => {
            const mapItem = this.mapper.find((v) => v.controlName === u);
            if (mapItem) {
                if (this.searchViewModel[u].hasStrictValue) {
                    params[mapItem.formKey] = this.searchViewModel[u].strictValue[mapItem.strictKey];
                }

                return;
            }
            if (u === 'offset') {
                params[u as string] = pageOffset ?? this.searchViewModel[u].strictValue;
                return;
            }

            if (dayjs.isDayjs(this.searchViewModel[u].strictValue)) {
                params[u as string] = dayjs(this.searchViewModel[u].strictValue).format();
                return;
            }
            params[u as string] = stringIsNullOrEmpty(this.searchViewModel[u].strictValue?.toString().trim())
                ? null
                : this.searchViewModel[u].strictValue;
        });

        if (resetOffsetRequired) {
            params['offset'] = 0;
        }

        return params;
    }

    private createPagingForm(): WlPagingForm {
        const keyExpressions: Array<KeyMap> = this.mapper.map((u) => {
            const newParamValue = this.searchViewModel[u.controlName].hasStrictValue ? this.searchViewModel[u.controlName].strictValue[u.strictKey] : null;
            const mapExpression: KeyMap = {
                sourceKey: u.controlName,
                targetKey: u.formKey,
                newValue: newParamValue,
            };

            return mapExpression;
        });

        return builderStrictToModel(WlPagingForm, this.searchViewModel, null, keyExpressions);
    }

    private compareParams(left: Params, right: Params): boolean {
        const keysGetter = (params: Params): Set<string | number> => new Set(getKeys(params)
            .filter(u => !isNullOrUndefined(params[u]))
            .sort((a: string, b: string) => a.localeCompare(b)));

        const leftKeys = keysGetter(left);
        const rightKeys = keysGetter(right);

        if (leftKeys.size !== rightKeys.size)
            return false;

        let isValid = true;
        leftKeys.forEach((u: string) => {
            if (!rightKeys.has(u) || right[u]?.toString() !== left[u]?.toString()) {
                isValid = false;
                return;
            }
        });

        return isValid;
    }

    private withFnParams(viewModelParams: Params): Params {
        if (!isNullOrUndefined(this.options.fnPageParamsSetter))
            this.options.fnPageParamsSetter(viewModelParams);

        return viewModelParams;
    }

    get searchState(): Observable<T> {
        return this.searchState$;
    }

    set loadingStatus(load: boolean) {
        if (this.innerLoadFlag) {
            this.innerLoadFlag.loading = load;
        }
    }

    get loadingStatus(): boolean {
        return this.innerLoadFlag.loading;
    }
}

class ReadyToLoadValidator {
    private initBuildersToLoad: number;
    private queryParamsIsValid = false;

    private validationSubject$ = new BehaviorSubject<boolean>(false);
    private initControlsMapped$ = new Subject<void>();


    constructor() {
    }

    public dispose(): void {
        this.validationSubject$?.unsubscribe();
        this.initControlsMapped$.unsubscribe();
    }

    public decreaseInitBuildersToLoad(): void {
        this.updateSubjectState(() => {
            if (this.initBuildersToLoad === 0) {
                return;
            }

            this.initBuildersToLoad--;
            if (this.initBuildersToLoad === 0) {
                this.initControlsMapped$.next();
            }
        });
    }

    public setMappersWithGetterCount(count: number): void {
        this.updateSubjectState(() => {
            this.initBuildersToLoad = count;
        });
    }

    public markInitControlsMapped(): void {
        if (this.initBuildersToLoad === 0) {
            this.initControlsMapped$.next();
        }
    }

    public setQueryParamsIsValid(value: boolean): void {
        this.updateSubjectState(() => this.queryParamsIsValid = value);
    }

    private updateSubjectState(setter?: () => void): void {
        setter = setter ?? (() => null);
        setter();

        const oldState = this.validationSubject$.value;
        const newState = this.isReadyToLoadData();
        if (newState !== oldState) {
            this.validationSubject$.next(newState);
        }
    }

    private isReadyToLoadData(): boolean {
        return this.initBuildersToLoad === 0 && this.queryParamsIsValid === true;
    }

    get controlsMapped(): Observable<void> {
        return this.initControlsMapped$.asObservable();
    }

    get isValid(): Observable<boolean> {
        return this.validationSubject$.asObservable();
    }
}
