import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, OnInit } from '@angular/core';
import { EventsService, MockAddGuideCashbackForm, WlGuideCashback } from '../../../../services/autogen/Events';
import { GaMessagingService, GaPagedResult, GaPagingForm, GaTableData, isNullOrUndefined } from '@koddington/ga-common';
import { map, take } from 'rxjs/operators';
import { AddGuideCashbackViewModel } from './models/add-guide-cashback-view-model';
import { AddGuideCashbackValidator } from './validators/add-guide-cashback-validator';
import { SearchNavigationService } from '../../../shared/services/search-navigation.service';
import { ActivatedRoute, Params } from '@angular/router';
import { GuideCashbackListViewModel } from './models/guide-cashback-list-view-model';
import { UsersListByIdForm } from '../../../../services/autogen/Shared';
import { builderStrictToModel } from '../../../shared/common/operation/builder-operation';
import { numbers } from '@material/select';


@UntilDestroy()
@Component({
    selector: 'app-add-guide-cashback',
    templateUrl: './add-guide-cashback.component.html',
    styleUrls: ['./add-guide-cashback.component.scss']
})
export class AddGuideCashbackComponent implements OnInit {
    public readonly addModel: AddGuideCashbackViewModel = new AddGuideCashbackViewModel();
    public readonly searchModel: GuideCashbackListViewModel = new GuideCashbackListViewModel();
    public pagedResult: GaPagedResult<WlGuideCashback>;
    public tableData: GaTableData<WlGuideCashback>;
    public loading = true;

    constructor(private readonly service: EventsService,
                private readonly validator: AddGuideCashbackValidator,
                private readonly _navigation: SearchNavigationService,
                private readonly _activeRoute: ActivatedRoute,
                private readonly messaging: GaMessagingService) {
    }

    public ngOnInit(): void {
        this.updateCurrentTableState();
    }
    protected add(): void {
        this.loading = true;
        const errors = this.validator.validate(this.addModel);
        if (errors.length > 0) {
            return;
        }
        const form = new MockAddGuideCashbackForm();
        form.name = this.addModel.name.strictValue;
        form.tultip = this.addModel.tultip.strictValue;

        this.service.addGuideCashback(form).pipe(take(1), untilDestroyed(this))
            .subscribe(value => {
                this.messaging.showMessage('Добавлена новая категория кэшбэка в справочник');
                this.updateCurrentTableState();
            });

    }

    private updateCurrentTableState() {
        this._activeRoute.queryParams.pipe(
            map(value => this.initFindViewModel(value)),
            map(() => this.createForm()),
            take(1)
        ).subscribe((value) => this.load(value));
    }

    private initFindViewModel(params: Params) {
        this.searchModel.categoryId.strictValue = !isNullOrUndefined(params['categoryId']) ? Number(params['categoryId']) : null;
        this.searchModel.categoryName.strictValue = !isNullOrUndefined(params['categoryName']) ? String(params['categoryName']) : null;
        this.searchModel.offset.strictValue = !isNullOrUndefined(params['offset']) ? Number(params['offset']) : 0;
        this.searchModel.count.strictValue = !isNullOrUndefined(params['count']) ? Number(params['count']) : 30;
    }
    private createForm(): UsersListByIdForm {
        return builderStrictToModel(UsersListByIdForm, this.searchModel);
    }

    private load(form: UsersListByIdForm): void {
        this.service.getGuideList(form)
            .pipe(untilDestroyed(this))
            .subscribe(res => {
                this.pagedResult = res;
                this.mapTableData(res.results);

                this.loading = false;
            });
    }

    public pageChanged(form: GaPagingForm): void {
        this.searchModel.offset.strictValue = form.offset;
        this.searchModel.count.strictValue = form.count;

        this.handleFilterChange();
    }
    private handleFilterChange(): void {
        const params: Params = {
            tab: 'CashbackGuide',
            categoryId: this.searchModel.categoryId.hasStrictValue ? Number(this.searchModel.categoryId.strictValue) : null,
            categoryName: this.searchModel.categoryName.hasStrictValue ? String(this.searchModel.categoryName.strictValue) : null,
            offset: this.searchModel.offset.hasStrictValue ? this.searchModel.offset.strictValue : 0,
            count: this.searchModel.count.hasStrictValue ? this.searchModel.count.strictValue : 30
        };

        this._navigation.search(this._activeRoute, params);
    }
    public search(): void {
        this.searchModel.offset.strictValue = 0;
        this.handleFilterChange();
    }
    private mapTableData(results: WlGuideCashback[]) {
        this.tableData = new GaTableData<WlGuideCashback>()
            .addSimpleColumn(u => u.categoryId, { title: 'Id категории', widthSize: 100 })
            .addSimpleColumn(u => u.name, { title: 'Название', widthSize: 100 })
            .addSimpleColumn(u => u.tultip, { title: 'Описание', widthSize: 100 })
            .setData(results);
    }
}
